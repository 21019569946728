.buttonStyle{
    background-color: transparent;
    border-radius: 5px;
    border: 1px solid blue;
    width: 30px;
    height: 38px;
    text-align: center;
    align-items: center;
    justify-content: center;
    display: flex;
    cursor: pointer;
}