.faqHeader button::after {
  background-image: url("/src/assets/image/plus-icon.png") !important;
  transform: rotateZ(45deg) !important;
  color: black !important;
}

.faqHeader button.collapsed::after {
  background-image: url("/src/assets/image/plus-icon.png") !important;
  transform: none !important;
  color: black !important;
}

.faqHeader button {
  background-color: #f0f5ff !important;
  border-radius: 20px;
  padding: 22px;
  outline: none !important;
  border: none !important;
  box-shadow: none !important;
  font-size: 20px;
  color: black !important;
}

.error-msg {
  color: #ff0404;
  font-size: 14px;
  font-weight: 400;
}

#sponsorsSlider .slick-track .slick-slide{
  display: flex;
  justify-content: center;
}

#sponsorsSlider .slick-slider .slick-arrow::before{
  color:#0d6efd;
  font-size: 25px;
}