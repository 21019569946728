.headerRow {
  background: radial-gradient(
    106.18% 99.29% at 47.53% 50%,
    #f1f5ff 0%,
    #eef3ff 100%
  );
  position: sticky;
  z-index: 9999;
  top: 0;
}

.logoImg{
  width: 75px;
}

.navLinkSpan{
  border-right: 1px solid #dee2e6;
}

.navigation_items{

}

@media screen and (max-width: 992px) {
  .navLinkSpan {
    padding: 0px !important;
    border-right: none !important;
  }
  .logoImg{
    width: 60px;
  }
  .navigation_items{
    text-align: right;
  }
  .navigation_items button{
    text-align: right;
  }
}
