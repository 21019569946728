html,
body {
  margin: 0px;
  padding: 0px;
}

* {
  font-family: "Poppins" !important;
}

.font-family-poppins {
  font-family: "Poppins" !important;
}

.font-family-poppins-bold {
  font-family: "Poppins-Bold" !important;
}

.no-background{
  background-color: transparent !important;
}

.background-blue{
  background-color: #0150FF !important;
}

.color-blue{
  color: #0150FF !important;
}

.font-20{
  font-size: 20px !important;
}

.font-24{
  font-size: 24px !important;
}

.font-48{
  font-size: 48px !important;
}

.font-64{
  font-size: 64px !important;
}

.bg-transparent{
  background: transparent !important;
}

.navbar {
  background: rgba(255, 255, 255, 0.6);
  border-radius: 0px 0px 63px 63px;
  padding: 30px 0px !important;
}