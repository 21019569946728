.homePageBanner {
  background-image: url(/src/assets/image/faq-cricles.png);
  background-position: center;
  background-repeat: no-repeat;
}

.homePageBannerRow {
  background: radial-gradient(
    106.18% 99.29% at 47.53% 50%,
    #f1f5ff 0%,
    #eef3ff 100%
  );
  min-height: 80vh;
  border-radius: 0px 0px 60px 60px;
}

.contactUsBtn {
  max-width: 150px;
}

.bannerImageCol {
  display: none !important;
}

.homeProducts img {
  object-fit: scale-down;
}

.productsItemContainer {
  width: 100%;
  height: 370px;
  background: #f0f5ff;
  border-radius: 45px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  padding: 20px;
}

.whyVotemaxRow {
  position: relative;
}

.whyVotemax {
  background: radial-gradient(
    106.18% 99.29% at 47.53% 50%,
    #f1f5ff 0%,
    #eef3ff 100%
  );
  border-radius: 60px;
}
.whyVotemax h2 {
  margin-top: 8rem;
}
.whyVotemax ul {
  list-style: none;
  padding: 0px;
  margin-left: 8px;
}
.whyVotemax ul li {
  padding-bottom: 20px;
  position: relative;
}

.whyVotemax ul li:last-child {
  padding-bottom: 0px;
  position: relative;
}

.borderLeftDotted{
  border-left: 1px dotted black;
}

.whyVotemax ul li img {
  position: absolute;
  left: -19px;
  top: -5px;
}

.ourSolutionsDiv {
  position: absolute;
  bottom: -180px;
}

.ourSolutions {
  background: linear-gradient(93.4deg, #0153ff 0.91%, #0048de 100%);
  box-shadow: 0px 22px 58px rgba(1, 80, 255, 0.35);
  border-radius: 32px;
  padding: 75px 0px;
}

.ourSolutionInputs,
.ourSolutionInputs:focus,
.ourSolutionInputs:active {
  background: rgba(255, 255, 255, 0.2) !important;
  border-radius: 10px !important;
  border: none !important;
  outline: 0px !important;
  height: 50px !important;
  color: white !important;
}

.ourSolutionInputs::-webkit-input-placeholder {
  color: white !important;
  font-weight: lighter;
}

.ourSolutionInputs:-ms-input-placeholder {
  color: white !important;
  font-weight: lighter;
}

.ourSolutionInputs::placeholder {
  color: white !important;
  font-weight: lighter;
}

.ourSolutionsFormBtn {
  min-height: 50px;
  padding: 0px;
}

.faqRow {
  background-image: url(/src/assets/image/faq-cricles.png);
  background-position: center;
  background-repeat: no-repeat;
}

.faqContainer {
  margin-top: 300px;
  margin-bottom: 150px;
}

.faqItems {
  border: none !important;
  background-color: #f0f5ff !important;
  margin-bottom: 15px;
  border-radius: 20px;
}

.sponsorsDiv {
  background: #f0f5ff;
  border-radius: 40px 40px 0px 0px;
  padding-top: 150px;
  padding-bottom: 150px;
}

.sponsorsItems {
  height: 200px;
  width: 200px !important;
  background: #ffffff;
  box-shadow: 0px 0px 5px rgb(1 80 255 / 15%);
  border-radius: 45px;
  display: flex !important;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-top: 35px !important;
  overflow: hidden;
  cursor: pointer;
}

.productsItemContainer:hover {
  transform: scale(1.02);
}

.whyVotemax{
  padding-bottom: 20rem;
}

@media screen and (max-width: 1199px) {
  .ourSolutionsDiv {
    bottom: -300px;
  }
  .faqContainer {
    margin-top: 350px;
    margin-bottom: 150px;
  }
  .whyVotemax{
    padding-bottom: 15rem;
  }
}

@media screen and (max-width: 991px) {
  .whyVotomaxImgCol {
    display: none;
  }
  .whyVotemaxListCol {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  .whyVotemax{
    padding-bottom: 35rem;
  }
}

@media screen and (max-width: 767px) {
  .whyVotemax{
    padding-bottom: 40rem;
  }
}

@media screen and (max-width: 500px) {
  .ourSolutionsDiv {
    bottom: -350px;
  }
  .faqContainer {
    margin-top: 400px;
    margin-bottom: 150px;
  }
  .whyVotemax{
    padding-bottom: 45rem;
  }
}

@media screen and (max-width: 400px) {
  
  .whyVotemax{
    padding-bottom: 50rem;
  }
}

@media screen and (min-width: 992px) {
  .bannerImageCol {
    display: flex !important;
  }
}
