.footerRow {
  background: #f0f5ff;
}

.footerDiv{
  background: #061029 !important;
  border-radius: 40px 40px 0px 0px;
  padding-top: 30px;
  padding-bottom: 100px;
}

.footerDiv nav{
    background-color: transparent !important;
    color: white !important;
}

.navLinks{
    color: white !important;
}